import axios from "axios";
import { refreshToken } from "./AxiosConfig";

const setToken = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const request = async (config) => {
  delete axios.defaults.headers.common["X-API-TOKEN"];
  delete axios.defaults.headers.common["Accept"];
  delete axios.defaults.headers.common["LOCAL-TIMEZONE"];
  delete axios.defaults.headers.common["PLATFORM"];

  const callBack = axios(config);
  return callBack
    .then(() => {
      return callBack;
    })
    .catch(async (error) => {
      console.log(
        "🚀 ~ file: AxiosConfig.js ~ request ~ error",
        error.response
      );
      if (error?.response?.status === 422) {
        return error;
      } else if (
        error?.response?.status === 400 ||
        error?.response?.status === 403 ||
        error?.response?.status === 404
      ) {
        return error.response;
      } else if (error.response == null) {
        return error;
      } else if (error?.response?.status === 401) {
        const result = await refreshToken();
        setToken(result);
        if (result !== null) {
          delete axios.defaults.headers.common["X-API-TOKEN"];
          delete axios.defaults.headers.common["X-JWT-TOKEN"];
          delete axios.defaults.headers.common["LOCAL-TIMEZONE"];
          delete axios.defaults.headers.common["PLATFORM"];
          try {
            config.headers = {
              common: {
                Authorization: `Bearer ${result}`,
              },
            };
          } catch (error) {
            console.log(error);
          }
          return axios(config);
        } else {
          return axios(config);
        }
      } else if (error?.response?.status === 503) {
        return error;
      } else if (error.code === "ECONNABORTED") {
        return axios(config);
      } else {
        if (error.response.data.message) {
          return error.response.data.message;
        }
        return error;
      }
    });
};

export { request };
