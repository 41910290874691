export const getMBCColorForValue = (value) => {
  if (value === null || value === 1.5 || value === 1.6) return "transparent";
  if (value <= 4) return "#52B534";
  if (value > 4 && value <= 10) return "#99E582";
  if (value > 10 && value <= 14) return "#F5D52E";
  if (value > 14 && value <= 19) return "#FC9012";
  if (value > 19 && value <= 25) return "#FC5D12";
  if (value > 25) return "#E42E37";
  return "#E42E37"; // for values > 30
};

export const getMBCColorName = (value) => {
  if (value === null || value === 1.5 || value === 1.6) return "transparent";
  if (value <= 4) return "Healthy";
  if (value > 4 && value <= 10) return "Low & Healthy";
  if (value > 10 && value <= 14) return "Mild";
  if (value > 14 && value <= 19) return "Moderate";
  if (value > 19 && value <= 25) return "Moderate to Severe";
  if (value > 25) return "Severe";
  return "#E42E37"; // for values > 30
};
