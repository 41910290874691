import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { getMBCColorForValue, getMBCColorName } from "../../utills/CommonFuncs";
import { Empty } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInbox } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const pointLabelsPlugin = {
  id: "pointLabels",
  afterDatasetsDraw(chart) {
    const ctx = chart.ctx;

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      if (!meta.hidden) {
        meta.data.forEach((element, index) => {
          const value = dataset.data[index];
          if (value !== null) {
            const { x, y } = element.tooltipPosition();
            if (value === 1.5) {
              const width = 70;
              const height = 32;
              const borderRadius = 18;
              ctx.fillStyle = "#C2CCE1";
              ctx.beginPath();
              ctx.moveTo(x - width / 2 + borderRadius, y - height / 2);
              ctx.lineTo(x + width / 2 - borderRadius, y - height / 2);
              ctx.arcTo(
                x + width / 2,
                y - height / 2,
                x + width / 2,
                y,
                borderRadius
              );
              ctx.lineTo(x + width / 2, y + height / 2 - borderRadius);
              ctx.arcTo(
                x + width / 2,
                y + height / 2,
                x + width / 2 - borderRadius,
                y + height / 2,
                borderRadius
              );
              ctx.lineTo(x - width / 2 + borderRadius, y + height / 2);
              ctx.arcTo(
                x - width / 2,
                y + height / 2,
                x - width / 2,
                y,
                borderRadius
              );
              ctx.lineTo(x - width / 2, y - height / 2 + borderRadius);
              ctx.arcTo(
                x - width / 2,
                y - height / 2,
                x - width / 2 + borderRadius,
                y - height / 2,
                borderRadius
              );
              ctx.closePath();
              ctx.fill();
              ctx.fillStyle = "white";
              ctx.font = "12px Arial";
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              ctx.fillText("Missed", x, y);
            } else if (value === 1.6) {
              const width = 32;
              const height = 32;
              const borderRadius = 18;

              ctx.fillStyle = "#883DBF";
              ctx.beginPath();
              ctx.moveTo(x - width / 2 + borderRadius, y - height / 2);
              ctx.lineTo(x + width / 2 - borderRadius, y - height / 2);
              ctx.arcTo(
                x + width / 2,
                y - height / 2,
                x + width / 2,
                y,
                borderRadius
              );
              ctx.lineTo(x + width / 2, y + height / 2 - borderRadius);
              ctx.arcTo(
                x + width / 2,
                y + height / 2,
                x + width / 2 - borderRadius,
                y + height / 2,
                borderRadius
              );
              ctx.lineTo(x - width / 2 + borderRadius, y + height / 2);
              ctx.arcTo(
                x - width / 2,
                y + height / 2,
                x - width / 2,
                y,
                borderRadius
              );
              ctx.lineTo(x - width / 2, y - height / 2 + borderRadius);
              ctx.arcTo(
                x - width / 2,
                y - height / 2,
                x - width / 2 + borderRadius,
                y - height / 2,
                borderRadius
              );
              ctx.closePath();
              ctx.fill();

              ctx.fillStyle = "white";
              ctx.font = "12px Arial";
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              ctx.fillText("", x, y);
            } else {
              ctx.fillStyle = "white";
              ctx.font = "12px Arial";
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              ctx.fillText(value.toString(), x, y);
            }
          }
        });
      }
    });
  },
};

export default function MbcGraph({ graphData }) {
  const values = graphData?.data?.map((i) => {
    if (!i?.is_submitted && !i.checkin_available) {
      return 1.5;
    } else if (!i?.is_submitted && i.checkin_available) {
      return 1.6;
    } else {
      return i?.score;
    }
  });

  const createDatasets = () => {
    const datasets = [];
    let currentSegment = [];
    let currentSegmentData = Array(values?.length)?.fill(null);

    values?.forEach((value, index) => {
      if (value === 1.5) {
        if (currentSegment.length > 0) {
          datasets.push({
            label: "",
            fill: false,
            backgroundColor: "rgb(75, 192, 192)",
            borderColor: "rgba(75, 192, 192, 0.2)",
            pointRadius: 13,
            pointHoverRadius: 16,
            data: [...currentSegmentData],
            pointBackgroundColor: currentSegmentData.map((v) =>
              getMBCColorForValue(v)
            ),
          });
        }
        const missedData = Array(values.length).fill(null);
        missedData[index] = value;

        datasets.push({
          label: "",
          fill: false,
          backgroundColor: "rgb(75, 192, 192)",
          borderColor: "transparent",
          pointRadius: 13,
          pointHoverRadius: 16,
          data: missedData,
          pointBackgroundColor: "yellow",
        });
        currentSegment = [];
        currentSegmentData = Array(values.length).fill(null);
      } else {
        currentSegment.push(value);
        currentSegmentData[index] = value;
      }
    });

    if (currentSegment.find((i) => i > 0) === undefined) {
      currentSegmentData.push(10);
      currentSegmentData.push(-3);
    }

    if (currentSegment.length > 0) {
      datasets.push({
        label: "",
        fill: false,
        backgroundColor: "rgb(75, 192, 192)",
        borderColor: "rgba(75, 192, 192, 0.2)",
        pointRadius: 13,
        pointHoverRadius: 16,
        data: currentSegmentData,
        pointBackgroundColor: currentSegmentData.map((v) =>
          getMBCColorForValue(v)
        ),
      });
    }
    return datasets;
  };

  const data = {
    labels: graphData?.data?.map(
      (i) =>
        moment.utc(i?.appointment_date).local().format("DD MMM") +
        ", Appointment " +
        i?.appointment_number
    ),
    datasets: createDatasets(),
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        usePointStyle: true,
        backgroundColor: "#27304b",
        enabled: false,
        external: function (context) {
          if (
            context.tooltip?.body?.[0].lines?.[0] > 1 &&
            context.tooltip?.body?.[0].lines?.[0] < 2
          ) {
            return;
          }

          let tooltipEl = document.getElementById("chartjs-tooltip");
          if (!tooltipEl) {
            tooltipEl = document.createElement("div");
            tooltipEl.id = "chartjs-tooltip";
            tooltipEl.innerHTML = "<table></table>";
            document.body.appendChild(tooltipEl);
          }
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }
          tooltipEl.classList.remove("above", "below", "no-transform");
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add("no-transform");
          }
          function getBody(bodyItem) {
            return {
              goals: [
                `Saif: ${bodyItem.lines}`,
                `Maisha: ${Number(bodyItem.lines) + 50}`,
              ],
              netWorth: [`Newaz: ${Number(bodyItem.lines) + 5}`],
            };
          }
          if (tooltipModel.body) {
            const bodyLines = tooltipModel.body.map(getBody);
            let score = tooltipModel?.body?.[0].lines?.[0];

            let innerHtml = "<thead>";
            innerHtml += "</thead><tbody>";
            bodyLines.forEach(function (body) {
              let style = "background:white";
              style += "; color: #27304b";
              style += "; padding: 10px 16px";
              style += "; border-radius: 10px";
              style += "; font-size: 14px";
              style += "; display: flex";
              style += "; flex-direction: column";
              style += "; box-shadow: 6px 8px 12px rgba(0, 0, 0, 0.1)";
              const goalSpan = `<div style="${style}">
                        <span style='display:flex; flex-direction: column;'>
                        <span style='color:${getMBCColorForValue(score)}',font-weight:bold>${score}/40 </span>
                        <span style='color:#25364E',font-weight:bold>${getMBCColorName(score)}</span>
                        </span>
                      </div>`;
              innerHtml += "<tr><td>" + goalSpan + "</td></tr>";
            });
            innerHtml += "</tbody>";
            let tableRoot = tooltipEl.querySelector("table");
            tableRoot.innerHTML = innerHtml;
          }
          const position = context.chart.canvas.getBoundingClientRect();
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = "absolute";
          tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX + "px";
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + "px";
          tooltipEl.style.padding =
            tooltipModel.padding + "px " + tooltipModel.padding + "px";
          tooltipEl.style.pointerEvents = "none";
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        display: true,
        min: 0,
        max: 40,
        grid: {
          display: true,
        },
        ticks: {
          autoSkip: false,
          padding: 10,
          stepSize: 5,
          callback: function (value) {
            return value;
          },
        },
      },
      x: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          padding: 10,
        },
      },
    },
  };

  if (graphData?.count === 0) {
    return (
      <Empty
        image={<FontAwesomeIcon icon={faInbox} style={{ color: "#f5f5f5" }} />}
        description={
          <p style={{ color: "#d3d3d3", fontSize: 16 }}>No Data Found</p>
        }
      />
    );
  }

  return (
    <div>
      <Line
        data={data}
        options={options}
        plugins={[pointLabelsPlugin]}
        style={{ height: 320 }}
      />
    </div>
  );
}