import { request } from "./MbcAxiosConfig";

const API_BASE_URL =
  "https://haoopknq0h.execute-api.ca-central-1.amazonaws.com/dev";

const get = async (url, params = {}) => {
  const Token = localStorage.getItem("token");
  const header = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${Token}`,
  };
  const config = {
    url,
    baseURL: API_BASE_URL,
    method: "get", // default
    headers: header,
    params: params,
    timeout: 30000, // default is `0` (no timeout)// 30 sec
    maxRedirects: 2, // default
  };
  return request(config);
};

const post = async (url, body) => {
  const Token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${Token}`,
  };
  const config = {
    url,
    baseURL: API_BASE_URL,
    method: "post",
    headers: headers,
    data: body,
    timeout: 30000,
    maxRedirects: 2,
  };
  return request(config);
};

const put = async (url, body) => {
  const Token = localStorage.getItem("token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${Token}`,
  };
  const config = {
    url,
    baseURL: API_BASE_URL,
    method: "put",
    headers: headers,
    data: body,
    timeout: 30000,
    maxRedirects: 2,
  };
  return request(config);
};

const http = {
  get,
  post,
  put,
};

export default http;
