import React, { useEffect } from "react";
import { Card, Col, DatePicker, Row, Select } from "antd";
import styles from "./styles.module.scss";

import { ClinicalOutcomes } from "../../assets/svg/ClinicalOutcomes";
import TagDescription from "./TagDescription";
import MbcGraph from "./MbcGraph";
import ScoringGuide from "./ScoringGuide";
import { AuthApi, MbcApi, MyJourneyApi } from "../../store/api";
import { useState } from "react";
import { API_MBC_URL } from "../../store/api/config/config";
import dayjs from "dayjs";
import { auth } from "../../utills/firebase-config";

const { RangePicker } = DatePicker;

export default function MyJourney() {
  const [graphData, setgraphData] = useState(null);
  const [startDate, setstartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  // useEffect(() => {
  //   if (endDate) {
  //     fetchGraphData();
  //   }
  // }, [endDate]);
  useEffect(() => {
    fetchGraphData();
  }, [endDate]);

  const fetchGraphData = async () => {
    let response;
    setgraphData(null);
    if (endDate) {
      response = await MbcApi.getMyJourneyGraph({
        start_date: startDate,
        end_date: endDate,
      });
    } else {
      response = await MbcApi.getMyJourneyGraph();
    }

    if (response?.status === 200) {
      setgraphData(response?.data);
    }
  };

  return (
    <div className={styles.container}>
      <Row
        gutter={16}
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <Col xs={24} sm={24} md={12} lg={12}>
          <p className={styles.h3} style={{ textAlign: "left" }}>
            My Journey
          </p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} style={{ textAlign: "left" }}>
          <Row gutter={16} style={{ justifyContent: "flex-end" }}>
            <Col>
              <p className={styles.s2}>Date Range</p>
              <RangePicker
                style={{ height: 45, borderRadius: 10 }}
                onChange={(e) => {
                  console.log(e);
                  if (e) {
                    setstartDate(dayjs(e[0]).format("YYYY-MM-DD"));
                    setendDate(dayjs(e[0]).format("YYYY-MM-DD"));
                  } else {
                    setstartDate(null);
                    setendDate(null);
                    // setTimeout(() => {
                    //   fetchGraphData();
                    // }, 500);
                  }

                  // setstartDate(dayjs(e[0]).format("YYYY-MM-DD"));
                  // setendDate(dayjs(e[0]).format("YYYY-MM-DD"));
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ClinicalOutcomes />
            <p
              className={styles.ct2}
              style={{ paddingLeft: 6, paddingRight: 6 }}
            >
              Clinical Outcomes (CORE-10)
            </p>
            <ScoringGuide />
          </div>
        </Col>
        <Col span={24}>
          <p
            className={styles.d3}
            style={{ paddingTop: 5, paddingBottom: 15, textAlign: "left" }}
          >
            How you’ve been doing
          </p>
        </Col>
      </Row>
      <Card>
        <TagDescription />
        {graphData && <MbcGraph graphData={graphData} />}
      </Card>
    </div>
  );
}
